import React from 'react'
import moment from 'moment'

import { Icon, Text } from '@Common/Components'
import noLogo from '@Common/Resources/Images/noLogo.webp'
import MessageViewer from './MessageViewer'
import state from '../State'

const DEFAULT_IMG = 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png'

export const Bubble = ({
  agencyId,
  changePopupState,
  avatarAgency,
  imgDefault,
  currentMessage,
  nextMessage,
  key,
  userData = {}
}) => {
  const config = state.config.state
  const cUser = currentMessage.user !== undefined ? currentMessage.user : null
  const nUser = nextMessage?.user !== undefined ? nextMessage.user : null
  const isTheFirstMessage = cUser && nUser === null
  const isAgency = cUser.id === agencyId
  const userIdExist = cUser !== null && nUser !== null
  const isTheFirstBubble = isTheFirstMessage || (userIdExist && nUser.id !== cUser.id)
  const equilizeAvatarAndBubble = 35
  let avatar = userData.avatar
    ? userData.avatar.includes(config.awsBaseURL) ? userData.avatar : config.awsBaseURL + userData.avatar
    : imgDefault
  if (!imgDefault) avatar = DEFAULT_IMG

  return (
    (userIdExist || isTheFirstMessage) && (
      <div
        key={key}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: !isAgency ? '' : 'flex-end',
          marginRight: !isAgency ? '' : 10
        }}
      >
        {!isAgency && isTheFirstBubble && avatar && (
          <div
            style={{
              display: 'block',
              width: 80,
              height: 80,
              borderRadius: '50%',
              border: '2px solid white',
              backgroundImage: `url(${avatar})`,
              backgroundPosition: 'top center',
              backgroundSize: 'cover',
              cursor: 'pointer'
            }}
            onClick={changePopupState}
          />
        )}
        {!isAgency && isTheFirstBubble && (
          <div
            style={{
              ...styles.paralleloLeft,
              marginTop: isTheFirstBubble ? equilizeAvatarAndBubble : 0
            }}
          />
        )}
        <div
          key={key}
          style={{
            ...styles.message,
            backgroundColor: isAgency ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.4)',
            marginRight: isAgency && !isTheFirstBubble ? (avatar ? 110 : 10) : 0,
            marginLeft: !isAgency && !isTheFirstBubble ? (avatar ? 100 : 10) : 0,
            marginTop: isTheFirstBubble ? equilizeAvatarAndBubble : 0,
            borderTopRightRadius: isAgency && isTheFirstBubble ? 0 : '8px',
            borderTopLeftRadius: !isAgency && isTheFirstBubble ? 0 : '8px',
            boxShadow: isAgency
              ? '2px 2px 2px rgba(120, 121, 125, 0.22)'
              : '2px 2px 2px rgba(0, 0, 0, 0.2)',
            color: isAgency ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.7)',
            position: 'relative',
            paddingBottom: 20
          }}
        >
          <MessageViewer currentMessage={currentMessage} isAgency={isAgency} isTheFirstBubble={isTheFirstBubble} />
          <div
            style={{
              position: 'absolute',
              bottom: 3,
              width: 30,
              right: 20
            }}
          >
            <Text
              italic
              color={isAgency ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.7)'}
              size={11}
              value={moment(currentMessage.createdAt).format('HH:mm')}
            />
          </div>

          {/*  SE IL MESSAGGIO E' LETTO */}
          {isAgency && (
            <>
              <Icon
                name='check' size={16} color={!currentMessage.read ? 'black' : 'rgb(0, 191, 255)'} style={{
                  position: 'absolute',
                  bottom: 3,
                  width: 30,
                  right: -0
                }}
              />
              <Icon
                name='check' size={16} color={!currentMessage.read ? 'black' : 'rgb(0, 191, 255)'} style={{
                  position: 'absolute',
                  bottom: 4,
                  width: 30,
                  right: -5
                }}
              />
            </>)}

        </div>
        {isAgency && isTheFirstBubble && (
          <div
            style={{
              ...styles.paralleloRight,
              marginTop: isTheFirstBubble ? equilizeAvatarAndBubble : 0
            }}
          />
        )}
        {isAgency && isTheFirstBubble && avatar && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '50%',
              width: 90,
              height: 90
            }}
          >
            <img
              src={avatarAgency || noLogo}
              alt='img agency'
              style={{ maxWidth: 55, maxHeight: 55 }}
            />
          </div>
        )}
      </div>
    )
  )
}

const styles = {
  message: {
    minWidth: '200px',
    maxWidth: '600px',
    minHeight: '60px',
    borderRadius: '8px',
    padding: 10,
    margin: 8
  },
  paralleloLeft: {
    width: '10px',
    height: '14px',
    borderTopLeftRadius: '0px',
    backgroundImage: 'radial-gradient(circle at 0 100%, #ff00 10px, rgba(255, 255, 255, 0.4) 5px)',
    marginLeft: 10
  },
  paralleloRight: {
    width: '12px',
    height: '14px',
    borderTopLeftRadius: '0px',
    backgroundImage: 'radial-gradient(circle at 100% 100%, #ff00 10px, rgba(0, 0, 0, 0.3) 5px)',
    marginRight: 10
  },
  avatarContainer: {
    width: 84,
    height: 84,
    margin: 3,
    backgroundColor: 'white',
    borderRadius: '50%'
  },
  avatarRender: {
    width: 80,
    height: 80,
    marginTop: 2,
    marginLeft: 2,
    borderRadius: '50%'
  }
}
